import { Typography } from '@mui/material';
import { StyledBannerContent } from '#/components/shared/ui/banner/styles';

interface BannerContentProps {
  title: string;
  description: string;
}

const BannerContent = ({ title, description }: BannerContentProps) => (
  <StyledBannerContent>
    <Typography variant="h2" component="h1" sx={{ mb: 2 }}>
      {title}
    </Typography>
    <Typography variant="subtitle1" fontWeight="light">
      {description}
    </Typography>
  </StyledBannerContent>
);

export default BannerContent;
