import { Typography } from '@mui/material';
import {
  SituationCardContent,
  SituationCardContentColumns,
} from '#/components/shared/ui/situation-card/styles';

interface Column {
  title: string;
  description: string[];
}

type ContentColumnsProps = {
  columns: Column[];
};

const ContentColumns = ({ columns }: ContentColumnsProps) => (
  <SituationCardContentColumns>
    {columns.map((column, index) => (
      <SituationCardContent key={index}>
        <Typography variant="h6">{column.title}</Typography>
        {column?.description?.map((desc, descIndex) => (
          <Typography key={descIndex}>{desc}</Typography>
        ))}
      </SituationCardContent>
    ))}
  </SituationCardContentColumns>
);

export default ContentColumns;
