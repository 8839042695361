export const LIVING_WILL_SITUATION = {
  service: 'patientenverfügung',
  content: {
    main: {
      title: '1. Patientenverfügung',
      description:
        'Mit einer Patientenverfügung stellen Sie sicher, dass Ihre medizinischen Wünsche im Ernstfall respektiert werden. Sie entscheiden, welche Behandlungen Sie erhalten möchten und welche nicht.',
    },
    columns: [
      {
        title: 'Warum jetzt',
        description: [
          'Im Alter nehmen gesundheitliche Unsicherheiten zu. Eine Patientenverfügung gibt Ihnen die Sicherheit, dass Ihre Entscheidungen auch dann respektiert werden, wenn Sie sie nicht mehr selbst treffen können.',
        ],
      },
      {
        title: 'Ihre Vorteile',
        description: [
          '• Selbstbestimmung im Ernstfall',
          '• Entlastung der Angehörigen',
          '• Rechtliche Sicherheit',
        ],
      },
    ],
  },
  media: {
    image: '/assets/images/vorsorge-situations/situation-lw.webp',
  },
  actions: {
    learnMore: 'Mehr erfahren',
    startProcess: 'Prozess starten',
  },
};

export const POA_SITUATION = {
  service: 'vorsorgeauftrag',
  content: {
    main: {
      title: '1. Vorsorgeauftrag',
      description:
        'Ein Vorsorgeauftrag regelt, wer für Sie und Ihre Familie entscheidet, falls Sie selbst dazu nicht mehr in der Lage sind. Schützen Sie sich und Ihr Kind durch eine klare Regelung.',
    },
    columns: [
      {
        title: 'Warum jetzt',
        description: [
          'Mit der Geburt eines Kindes tragen Sie zusätzliche Verantwortung. Ein Vorsorgeauftrag stellt sicher, dass Ihr Kind auch im Ernstfall gut betreut und versorgt wird.',
        ],
      },
      {
        title: 'Ihre Vorteile',
        description: [
          '• Selbst bestimmen, wer sich um Ihr Kind kümmert',
          '• Rechtliche Sicherheit für Ihre Familie',
          '• Schutz im Krankheits- oder Notfall',
        ],
      },
    ],
  },
  media: {
    image: '/assets/images/vorsorge-situations/situation-poa.webp', // Leave empty as requested
  },
  actions: {
    learnMore: 'Mehr erfahren',
    startProcess: 'Prozess starten',
  },
};

export const LAST_WILL_SITUATION = {
  service: 'testament',
  content: {
    main: {
      title: '2. Testament',
      description:
        'Mit einem Testament legen Sie fest, wie Ihr Vermögen im Todesfall aufgeteilt wird. Sie können sicherstellen, dass Ihr Kind finanziell abgesichert ist und Ihre Wünsche respektiert werden.',
    },
    columns: [
      {
        title: 'Warum jetzt',
        description: [
          'Ein Testament gibt Ihnen die Sicherheit, dass Ihr Kind im Falle Ihres Todes gut versorgt ist und dass Ihre Vermögenswerte nach Ihren Wünschen verteilt werden.',
        ],
      },
      {
        title: 'Ihre Vorteile',
        description: [
          '• Finanzielle Sicherheit für Ihr Kind',
          '• Rechtliche Klarheit über Erbfolgen',
          '• Schutz des Familienvermögens',
        ],
      },
    ],
  },
  media: {
    image: '/assets/images/vorsorge-situations/situation-tes.webp',
  },
  actions: {
    learnMore: 'Mehr erfahren',
    startProcess: 'Prozess starten',
  },
};

export const MARRIAGE_CONTRACT_SITUATION = {
  service: 'ehevertrag',
  content: {
    main: {
      title: '3. Ehevertrag',
      description:
        'Mit einem Ehevertrag schaffen Sie klare Regelungen in Bezug auf das Vermögen und die Absicherung Ihres Kindes. So sorgen Sie vor, falls sich Ihre Lebensumstände ändern.',
    },
    columns: [
      {
        title: 'Warum jetzt',
        description: [
          'Jetzt, da ein Kind Teil Ihrer Familie ist, bietet ein Ehevertrag zusätzlichen Schutz für alle. Er schafft klare Verhältnisse für Vermögensfragen und die Versorgung Ihres Kindes.',
        ],
      },
      {
        title: 'Ihre Vorteile',
        description: [
          '• Schutz des Kindesvermögens',
          '• Klare Regelungen im Falle einer Trennung',
          '• Vermeidung von Streitigkeiten',
        ],
      },
    ],
  },
  media: {
    image: '/assets/images/vorsorge-situations/situation-mc.webp',
  },
  actions: {
    learnMore: 'Mehr erfahren',
    startProcess: 'Prozess starten',
  },
};

export const DIGITAL_FOOTPRINT_SITUATION = {
  service: 'digitaler_nachlass',
  content: {
    main: {
      title: '4. Digitaler Nachlass',
      description:
        'Ihr digitales Leben hinterlässt Spuren – von Online-Accounts bis zu Social Media. Mit einer Regelung für Ihren digitalen Nachlass bestimmen Sie, was nach Ihrem Tod mit Ihren Daten geschieht.',
    },
    columns: [
      {
        title: 'Warum jetzt',
        description: [
          'Im digitalen Zeitalter ist es wichtig, auch den digitalen Nachlass zu regeln, um den Umgang mit sensiblen Daten zu sichern.',
        ],
      },
      {
        title: 'Ihre Vorteile',
        description: [
          '• Schutz Ihrer digitalen Identität',
          '• Vermeidung von Missbrauch',
          '• Entlastung der Angehörigen',
        ],
      },
    ],
  },
  media: {
    image: '/assets/images/vorsorge-situations/situation-df.webp',
  },
  actions: {
    learnMore: 'Mehr erfahren',
    startProcess: 'Prozess starten',
  },
};

export const FINANCIAL_SITUATION = {
  service: 'financial_consultation',
  content: {
    main: {
      title: '5. Beratung zur Finanzsituation',
      description:
        'Im Ruhestand ändert sich oft die finanzielle Situation. Unsere Beratung hilft Ihnen, Ihre Finanzen optimal zu planen, damit Sie auch im Alter finanziell abgesichert sind.',
    },
    columns: [
      {
        title: 'Warum jetzt',
        description: [
          'Eine gute Finanzplanung gibt Ihnen im Ruhestand Sicherheit und sorgt dafür, dass Sie Ihre neue Lebensphase entspannt genießen können.',
        ],
      },
      {
        title: 'Ihre Vorteile',
        description: [
          '• Optimale Nutzung Ihrer Renteneinkünfte',
          '• Steuerliche Vorteile',
          '• Langfristige finanzielle Absicherung',
        ],
      },
    ],
  },
  media: {
    image: '/assets/images/vorsorge-situations/situation-finances.webp',
  },
  actions: {
    learnMore: 'Mehr erfahren',
    startProcess: 'Prozess starten',
  },
};
