import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

export const StyledSituationCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

export const SituationCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const SituationCardContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const SituationCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const SituationCardContentColumns = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(2),
}));

export const SituationCardMedia = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '16 / 9',
    },
  },
}));

export const SituationCardActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
}));
