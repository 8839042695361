import { useLocation } from 'react-router-dom';
import Banner from '#/components/shared/ui/banner';
import { LandingContainer } from '#/components/pages/LSLandings/styles';
import SituationCard from '#/components/shared/ui/situation-card';
import { landingContent } from '#/components/pages/LSLandings/landing-content';

export default function Landing() {
  const test = useLocation();

  const url = test.pathname.split('/').pop();

  const content = landingContent[url as keyof typeof landingContent];

  return (
    <LandingContainer>
      <Banner
        title={content.bannerTitle}
        description={content.bannerDescription}
        image={content.bannerImg}
      />
      {content.cards.map((card) => (
        <SituationCard key={card.service} situation={card} />
      ))}
    </LandingContainer>
  );
}
