import Landing from '#/components/pages/LSLandings';
import Page from '#/components/shared/ui/Page';

export default function LSLandings() {
  return (
    <Page title="LSLandings">
      <Landing />;
    </Page>
  );
}
