import { Button, Typography } from '@mui/material';
import { SituationCardActions } from '#/components/shared/ui/situation-card/styles';

type CardActionsProps = {
  actions: {
    learnMore: string;
    startProcess: string;
  };
};

const CardActions = ({ actions }: CardActionsProps) => (
  <SituationCardActions>
    <Button variant="outlined" color="primary">
      <Typography>{actions.learnMore}</Typography>
    </Button>
    <Button variant="contained" color="primary">
      <Typography>{actions.startProcess}</Typography>
    </Button>
  </SituationCardActions>
);

export default CardActions;
