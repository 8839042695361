import { Typography } from '@mui/material';
import {
  SituationCardContainer,
  SituationCardContent,
  SituationCardContentContainer,
  SituationCardMedia,
  StyledSituationCard,
} from '#/components/shared/ui/situation-card/styles';
import Image from '#/components/shared/ui/Image';
import { Situation } from '#/components/shared/ui/situation-card/types';
import ContentColumns from '#/components/shared/ui/situation-card/content-columns';
import CardActions from '#/components/shared/ui/situation-card/card-actions';

type SituationCardProps = {
  situation: Situation;
};

export default function SituationCard({ situation }: SituationCardProps) {
  const { content, media, actions } = situation;

  return (
    <StyledSituationCard>
      <SituationCardContainer>
        <SituationCardContentContainer>
          <SituationCardContent>
            <Typography variant="h3" component="h6">
              {content.main.title}
            </Typography>
            <Typography>{content.main.description}</Typography>
          </SituationCardContent>
          <ContentColumns columns={content.columns} />
        </SituationCardContentContainer>
        {media.image && (
          <SituationCardMedia>
            <Image src={media.image} alt="Situation card image" />
          </SituationCardMedia>
        )}
      </SituationCardContainer>
      <CardActions actions={actions} />
    </StyledSituationCard>
  );
}
