import {
  DIGITAL_FOOTPRINT_SITUATION,
  FINANCIAL_SITUATION,
  LAST_WILL_SITUATION,
  LIVING_WILL_SITUATION,
  MARRIAGE_CONTRACT_SITUATION,
  POA_SITUATION,
} from '#/components/pages/LSLandings/constants';
import { Situation } from '#/components/shared/ui/situation-card/types';

type LsLandingContent = {
  [key: string]: {
    bannerImg: string;
    bannerTitle: string;
    bannerDescription: string;
    cards: Situation[];
  };
};

export const landingContent: LsLandingContent = {
  ehevertrag: {
    bannerImg: '/assets/images/vorsorge-banners/vorsorge-marriage.webp',
    bannerTitle: 'Heiraten heisst Verantwortung  übernehmen. Und vorsorgen.',
    bannerDescription:
      'Heiraten bedeutet, Verantwortung zu übernehmen. Für sich selbst, für Ihren Ehepartner, für Ihre Familie. Wir helfen Ihnen, in guten Zeiten für die schlechten Zeiten vorzusorgen.',
    cards: [MARRIAGE_CONTRACT_SITUATION, LAST_WILL_SITUATION],
  },

  geburt: {
    bannerImg: '/assets/images/vorsorge-banners/birthOfChild.webp',
    bannerTitle: 'Ein Kind bedeutet Liebe. Und Verantwortung für die Zukunft.',
    bannerDescription:
      'Die Geburt eines Kindes ist ein wunderbares Ereignis, das das Leben auf den Kopf stellt. Jetzt ist der perfekte Zeitpunkt, um nicht nur an die schönen Momente, sondern auch an die Absicherung Ihrer Familie zu denken. Gemeinsam sorgen wir dafür, dass Ihr Kind und Ihre Liebsten immer bestens versorgt sind – egal, was das Leben bringt.',
    cards: [POA_SITUATION, LAST_WILL_SITUATION, MARRIAGE_CONTRACT_SITUATION],
  },

  'kauf-einer-immobilie': {
    bannerImg: '/assets/images/vorsorge-banners/purchaseOfRealEstate.webp',
    bannerTitle:
      'Ein Eigenheim schafft Sicherheit. Sorgen Sie vor, damit das so bleibt.',
    bannerDescription:
      'Der Kauf eines Eigenheims ist ein grosser Schritt in die Zukunft. Damit diese Sicherheit auch in schwierigen Zeiten bestehen bleibt, ist es wichtig, rechtzeitig vorzusorgen. Wir helfen Ihnen, Ihre Vorsorgepläne auf die neue Lebenssituation anzupassen, damit Sie sich ganz auf Ihr neues Zuhause konzentrieren können.',
    cards: [POA_SITUATION, MARRIAGE_CONTRACT_SITUATION],
  },

  pensionieren: {
    bannerImg: '/assets/images/vorsorge-banners/enteringRetirement.webp',
    bannerTitle:
      'Entspannt in den Ruhestand. Sorgen Sie vor, um in Ruhe geniessen zu können.',
    bannerDescription:
      'Mit dem Eintritt ins Rentenalter beginnt eine Zeit der Entspannung und des Rückblicks auf das Erreichte. Doch gerade jetzt ist es wichtig, über die Zukunft nachzudenken. Wir helfen Ihnen dabei, alles in die richtigen Bahnen zu lenken, damit Sie Ihren Ruhestand unbeschwert geniessen können.',
    cards: [
      LIVING_WILL_SITUATION,
      LAST_WILL_SITUATION,
      POA_SITUATION,
      DIGITAL_FOOTPRINT_SITUATION,
      FINANCIAL_SITUATION,
    ],
  },

  'tod-eines-verwandten': {
    bannerImg: '/assets/images/vorsorge-banners/deathOfARelative.webp',
    bannerTitle:
      'Der Verlust eines geliebten Menschen hinterlässt Spuren. Sorgen Sie dafür, dass nichts verloren geht.',
    bannerDescription:
      'Der Tod eines Verwandten ist eine schwere Zeit, in der vieles geregelt werden muss. Neben der Trauer gibt es oft auch Fragen zum digitalen Nachlass und den materiellen Werten des Verstorbenen. Mit einem klaren Vermögens- und Sachregister sowie einer Regelung für den digitalen Nachlass schaffen Sie Ordnung und helfen Ihren Hinterbliebenen, alles in Ihrem Sinne zu verwalten.',
    cards: [DIGITAL_FOOTPRINT_SITUATION, FINANCIAL_SITUATION],
  },

  firmengrundung: {
    bannerImg: '/assets/images/vorsorge-banners/foundingACompany.webp',
    bannerTitle:
      'Eine Firmengründung ist der Start von etwas Grossem. Sorgen Sie dafür, dass es auch in unsicheren Zeiten Bestand hat.',
    bannerDescription:
      'Mit der Gründung eines Unternehmens setzen Sie Ihre Vision in die Tat um und schaffen etwas von bleibendem Wert. Doch gerade in dieser aufregenden Zeit ist es wichtig, sich abzusichern – sowohl persönlich als auch geschäftlich. Mit einem Vorsorgeauftrag und einem Testament stellen Sie sicher, dass Ihr Unternehmen und Ihre Familie auch dann geschützt sind, wenn das Leben unvorhergesehene Wendungen nimmt.',
    cards: [POA_SITUATION, LAST_WILL_SITUATION],
  },

  scheidung: {
    bannerImg: '/assets/images/vorsorge-banners/divorce.webp',
    bannerTitle:
      'Eine Scheidung ist ein Neuanfang. Sorgen Sie dafür, dass alles geregelt ist.',
    bannerDescription:
      'Eine Scheidung kann eine herausfordernde Zeit sein. Es ist wichtig, dass Sie in dieser Phase gut informiert sind und die richtigen Entscheidungen treffen. Wir helfen Ihnen, alle notwendigen Regelungen zu treffen, damit Sie und Ihre Familie gut abgesichert sind und einen Neuanfang wagen können.',
    cards: [POA_SITUATION, FINANCIAL_SITUATION],
  },

  krankheit: {
    bannerImg: '/assets/images/vorsorge-banners/illness.webp',
    bannerTitle:
      'Eine schwere Diagnose bringt Unsicherheit. Wir helfen Ihnen, Klarheit zu schaffen.',
    bannerDescription:
      'Eine schwere Krankheit verändert alles. In dieser schwierigen Zeit ist es umso wichtiger, sich auf das Wesentliche zu konzentrieren – Ihre Gesundheit und die Menschen, die Ihnen am Herzen liegen. Mit einer Patientenverfügung und einem Testament sorgen Sie dafür, dass Ihre Wünsche respektiert und Ihre Liebsten abgesichert sind, wenn Sie selbst es nicht mehr regeln können.',
    cards: [LIVING_WILL_SITUATION, LAST_WILL_SITUATION],
  },
};
